















































































/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TreeView } from '@/types/course.type';
import DialogComponent from '@/components/dialog.component.vue';
import { APPIDS } from '@/enums';
import IloProvider from '@/providers/ilos.provider';
import { Lang } from '@/types/general.type';
import Toaster from '../../mixins/toaster.mixin';
import RecursiveILOsPanel from './RecursiveILOsPanel.component.vue';

@Component({
  components: {
    DialogComponent,
    RecursiveILOsPanel,
  },
})
export default class FlexibleIlosAddEditComponent extends mixins(Toaster) {
  appId = APPIDS.COURSE_ILOS;

  maxAllowedFlexibleIlosHeaders = 12;

  ilos: TreeView[] = [];

  @Prop(Array) readonly currIlos!: TreeView[];

  showCancelConfirm = false;

  showDeleteConfirm = false;

  deleteChild = () => {};

  mounted() {
    this.ilos = JSON.parse(JSON.stringify(this.currIlos));
  }

  get courseId() {
    return this.$route ? this.$route.params.courseId : '';
  }

  get canSave(): boolean {
    return !!this.ilos.length && this.validateItemNameRec(this.ilos);
  }

  validateItemNameRec(ilos: TreeView[], isLevelZero = true): boolean {
    let valid = true;
    ilos.forEach((ilo) => {
      if (ilo.children.length > 0) {
        if (!this.validateItemNameRec(ilo.children, false)) valid = false;
      }
      if (!this.validateItemName(ilo, isLevelZero)) valid = false;
    });
    return valid;
  }

  async submit(): Promise<void> {
    (this.$refs.formRef as HTMLFormElement).validate();
    if (this.canSave) {
      try {
        const { message, success } = await IloProvider.AddFlexibleILOs(
          this.appId,
          this.courseId,
          this.ilos,
        );
        if (success) {
          this.successToaster(message as Lang);
          this.$emit('saved-successfully');
        }
      } catch (err) {
        this.errorToaster(err as Lang);
      }
    }
  }

  validateILOsCount(children: TreeView[], index: string): boolean {
    const indices = index ? index.split('.') : [];
    return (
      (!!indices.length || children.length >= this.maxAllowedFlexibleIlosHeaders) && (!indices.length || children.length >= 30)
    );
  }

  validateItemName(item: TreeView, isLevelZero: boolean): boolean {
    const name = item.name ? item.name.trim() : '';
    return (
      !!name
      && name.length >= 5
      && ((isLevelZero && name.length <= 50) || (!isLevelZero && name.length <= 255))
    );
  }

  appendChild(props: { children: TreeView[]; index: string }): void {
    const indices = props.index ? props.index.split('.') : [];
    if (
      (!indices.length && props.children.length < this.maxAllowedFlexibleIlosHeaders)
      || (!!indices.length && props.children.length < 30)
    ) {
      props.children.push({
        name: '',
        children: [],
      });
    }
  }

  onClickDeleteConfirm(): void {
    this.deleteChild();
    this.deleteChild = () => {};
    this.showDeleteConfirm = false;
  }

  onClickCancelConfirm(): void {
    this.ilos = [];
    this.$emit('disable-ilos-type-btn');
    this.showCancelConfirm = false;
  }

  deleteElement(props: { index: string }): void {
    const { parent, index } = this.getElementParent(this.ilos, props.index);
    this.deleteChild = () => {
      parent.splice(index, 1);
    };
    if (parent[index].children && parent[index].children.length > 0) {
      this.showDeleteConfirm = true;
    } else {
      this.deleteChild();
    }
  }

  getElementParent(parent: TreeView[], index: string, level = 0) {
    const indices = index.split('.');
    const currentIndex = parseInt(indices[level], 10) - 1;
    if (indices.length === level + 2) {
      return { parent, index: currentIndex };
    }
    return this.getElementParent(parent[currentIndex].children, index, level + 1);
  }
}
