











































































































































































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import IloProvider from '@/providers/ilos.provider';
import {
  Rights, COURSE_TYPE, APPIDS, COURSES_ILOS_ROUTE_NAME,
  ILOS_OPTIONS_IDS,
} from '@/enums';

import { TreeView } from '@/types/course.type';
import {
  IlosDataResponse,
  IlosAndHeaderIds,
  IloReqObj,
} from '@/types/ilo.type';

import DialogComponent from '@/components/dialog.component.vue';
import SelectedCourse from '../SelectedCourse.component.vue';
import { Lang } from '../../types/general.type';
import Toaster from '../../mixins/toaster.mixin';
import FlexibleIlosAddEditComponent from './FlexibleIlosAddEdit.component.vue';

@Component({
  components: {
    SelectedCourse,
    DialogComponent,
    FlexibleIlosAddEditComponent,
  },
})
export default class CourseIlosListView extends mixins(Toaster) {
  appId = APPIDS.COURSE_ILOS;

  CourseData!: IlosDataResponse;

  showDeleteDialog = false;

  showCancelConfirm = false;

  loadingDelete = false;

  CourseUrl = `/courses/${this.courseId}`;

  courseIlos!: TreeView[];

  loadingIlosLang = false;

  validForm = true;

  IloCountValid = false;

  showAddForm = false;

  editForm = false;

  showIlosLangDialog = false;

  availableLang: string[] = [];

  openedPanel: number[] = [];

  openedPanelHeader: number[] = [0, 1, 2, 3];

  ilosIds: number[] = [];

  ilosLangInfoMessage: Lang = { ar: '', en: '' };

  translatedCourseIlos: TreeView[] = [];

  chosenLang = '';

  $options!: {
    filters: {
      servertranslate;
    };
  };

  servertranslate = this.$options.filters.servertranslate;

  loading = true;

  maxAllowedIlosPerHeader = 100;

  error = '';

  $refs!: {
    form: HTMLFormElement;
  };

  showResetTypeConfirm = false;

  ILOS_OPTIONS_IDS = ILOS_OPTIONS_IDS;

  ilosOptions = [
    { id: ILOS_OPTIONS_IDS.CUSTOM, name: this.$t('labels.CUSTOM_ILOS') },
    { id: ILOS_OPTIONS_IDS.FIXED, name: this.$t('labels.FIXED_ILOS') },
  ];

  ilosType = '';

  disableIlosTypeBtn = false;

  flexibleIlos = false;

  onSaveFlexibleILOs() {
    window.location.reload();
  }

  onClickIlosBtn() {
    if (this.disableIlosTypeBtn) {
      this.showCancelConfirm = true;
      this.showResetTypeConfirm = true;
    } else {
      this.disableIlosTypeBtn = true;
    }
  }

  enableIloAdd(ilos) {
    return ilos.filter((item) => item.deleted === false).length < this.maxAllowedIlosPerHeader;
  }

  get translatedCourseIlosForView() {
    return this.CourseData.courseIlos.map((ilo) => ({
      ...ilo,
      name: this.servertranslate(
        this.CourseData.MainIlos.find(
          (headerIlo) => headerIlo.baID === ilo.headerId,
        )?.baName ?? '',
      ),
    }));
  }

  get canSave() {
    return this.IloCountValid && this.validForm;
  }

  editIlos() {
    this.showAddForm = true;
    this.editForm = true;
    this.disableIlosTypeBtn = !!this.ilosType;
  }

  get courseId() {
    return this.$route ? this.$route.params.courseId : '';
  }

  onBack() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: COURSES_ILOS_ROUTE_NAME.COURSES_LIST, query: courseFilters });
  }

  addILo(iLoHeaderId, iLoHeaderindex) {
    this.$refs.form.validate();
    const lastSubIloInputIndex = this.translatedCourseIlos[iLoHeaderindex]
      .children[0]
      ? this.translatedCourseIlos[iLoHeaderindex].children[0].inputIndex || 0
      : -1;
    this.translatedCourseIlos[iLoHeaderindex].children.push({
      name: '',
      iloId: '',
      headerId: '',
      inputIndex: lastSubIloInputIndex + 1,
      inputkey: `inputKey-${iLoHeaderId}-${lastSubIloInputIndex + 1}`,
      deleted: false,
      children: [],
    });
    this.IloCountValid = true;
    this.openedPanelHeader = [iLoHeaderindex];
  }

  showDeleteConfirm() {
    this.showDeleteDialog = true;
  }

  async DeleteIlos() {
    try {
      const { message } = await IloProvider.DeleteIlos(
        this.appId,
        this.courseId,
      );

      this.successToaster(message as Lang);
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.showDeleteDialog = false;
    }
  }

  formateEditAndAddIlos() {
    return this.translatedCourseIlos.reduce((iloHeaderAcc, iloHeader) => {
      if (iloHeader.headerId) {
        /* eslint-disable no-param-reassign */

        iloHeaderAcc[iloHeader.headerId] = iloHeader.children.reduce(
          (acc: IloReqObj[], ilo) => {
            if (ilo.iloId || !ilo.deleted) {
              acc.push({
                name: ilo.name as string,
                deleted: ilo.deleted as boolean,
                iloId: ilo.iloId as string,
              });
            }
            return acc;
          },
          [],
        );

        /* eslint-enable no-param-reassign */
      }
      return iloHeaderAcc;
    }, {});
  }

  async onClickCancelConfirm() {
    if (this.showResetTypeConfirm) {
      this.showCancelConfirm = false;
      this.showResetTypeConfirm = false;
      this.translatedCourseIlos = this.translatedCourseIlos.map((iloHeader) => ({ ...iloHeader, children: [], iloId: '' }));
      this.disableIlosTypeBtn = false;
    } else {
      this.showCancelConfirm = false;
      this.loading = true;
      this.$router.go(0);
    }
  }

  async saveIlos() {
    // Add and Edit  Ilos
    try {
      const ilos = this.formateEditAndAddIlos();
      const { message } = await IloProvider.AddIlos(this.appId, this.courseId, {
        ilos,
      });
      this.successToaster(message as Lang);
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    } catch (err) {
      this.errorToaster(err);
      this.showDeleteDialog = false;
    }
  }

  enableIloRemove() {
    return (
      this.translatedCourseIlos.reduce(
        (sum, iloItem) => sum + iloItem.children.filter((Ilo) => Ilo.deleted === false).length,
        0,
      ) > 1
    );
  }

  removeIlo(deleted, children, iloIndex, iLoHeaderIndex) {
    if (
      this.enableIloAdd(children)
      || (!this.enableIloAdd(children) && !deleted)
    ) {
      const ilosCount = this.translatedCourseIlos.reduce(
        (sum, iloItem) => sum + iloItem.children.filter((Ilo) => Ilo.deleted === false).length,
        0,
      );
      /* eslint-disable max-len */
      if (ilosCount > 1 || (ilosCount === 1 && deleted)) {
        this.translatedCourseIlos[iLoHeaderIndex].children[iloIndex].deleted = !this.translatedCourseIlos[iLoHeaderIndex].children[iloIndex].deleted;
      } else {
        this.errorToaster(this.$t('minILOs'));
      }
    } else {
      this.errorToaster(this.$t('maxILOs'));
    }
  }

  checkIloUsed(ilo) {
    return !!(ilo?.iloId && this.ilosIds.includes(ilo.iloId));
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get canEdit() {
    return (
      this.CourseData.rights.includes(Rights.add)
      && this.isExternalCourse
      && !this.showAddForm
    );
  }

  get canAdd() {
    return this.CourseData.rights.includes(Rights.add) && this.isExternalCourse;
  }

  get isExternalCourse() {
    return (
      this.CourseData.course.courseType === COURSE_TYPE.EXTERNAL_COURSE
      || this.CourseData.course.courseType === COURSE_TYPE.EXTERNAL_COURSE_PGS
      // || this.CourseData.isExternalFoundation
      || this.CourseData.isCompetencies
      // || this.CourseData.course.flexibleIlos
    );
  }

  get canDelete() {
    return (
      this.CourseData.rights.includes(Rights.add)
      && this.CourseData.canDelete
      && !this.showAddForm
      && this.isExternalCourse
    );
  }

  get disableResetBtn() {
    return (!this.disableIlosTypeBtn && !this.ilosType) || !this.CourseData.canDelete;
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
    this.getCourseIlos();
  }

  async getCourseIlos() {
    try {
      this.loading = true;
      const response: IlosDataResponse = await IloProvider.GetIlos(
        this.appId,
        this.courseId,
      );
      this.CourseData = { ...response };
      this.flexibleIlos = response.course.flexibleIlos || false;

      // map main ilos header to treeview in add form
      let MainIlos;
      if (!this.flexibleIlos) {
        MainIlos = this.CourseData.MainIlos.map((headerIlo) => ({
          headerId: headerIlo.baID,
          name: this.servertranslate(headerIlo.baName),
          iloId: '',
          children: [],
        }));
      } else {
        MainIlos = response.courseIlos;
      }

      const { courseIlos, existHeaderIlosIds } = response.courseIlos.reduce(
        (iloACC, Ilo) => {
          iloACC.courseIlos.push({
            ...Ilo,
            name: this.flexibleIlos ? Ilo.name : this.servertranslate(Ilo.name),
          });
          iloACC.existHeaderIlosIds.push(Ilo.headerId ? Ilo.headerId : '');
          return iloACC;
        },
        { courseIlos: [], existHeaderIlosIds: [] } as IlosAndHeaderIds,
      );
      this.ilosIds = response.usedIlosAndSub.usedIlosIds;
      if (courseIlos.length > 0) {
        this.showAddForm = false;
        this.validForm = true;
        this.IloCountValid = true;
        this.courseIlos = courseIlos;
        const remainingHeaderIlos = MainIlos.filter(
          (headerIlo) => !existHeaderIlosIds.includes(
            headerIlo.headerId ? headerIlo.headerId : '',
          ),
        );
        this.courseIlos.push(...remainingHeaderIlos);
        this.ilosType = response.course.flexibleIlos
          ? ILOS_OPTIONS_IDS.CUSTOM
          : ILOS_OPTIONS_IDS.FIXED;
      } else {
        if (response.course.flexibleIlos !== undefined) {
          this.ilosType = response.course.flexibleIlos
            ? ILOS_OPTIONS_IDS.CUSTOM
            : ILOS_OPTIONS_IDS.FIXED;
        } else this.ilosType = '';
        this.showAddForm = true;
        this.validForm = false;
        this.IloCountValid = false;
        this.courseIlos = MainIlos;
      }

      this.translatedCourseIlos = this.courseIlos.map((ilo) => ({
        ...ilo,
        name: (!this.flexibleIlos) ? this.servertranslate(
          this.CourseData.MainIlos.find(
            (headerIlo) => headerIlo.baID === ilo.headerId,
          )?.baName ?? '',
        ) : '',
        children: ilo.children.map((subIlo, subIloIndex) => ({
          ...subIlo,
          inputIndex: ilo.children.length - 1 - subIloIndex,
          inputkey: `inputKey-${ilo.headerId}-${
            ilo.children.length - 1 - subIloIndex
          }`,
        })),
      }));
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.error = err;
    }
  }

  validateIlo(deleted) {
    if (deleted) {
      return true;
    }
    return [
      (v: string) => (v && v.trim().length <= 255)
        || this.$t('validations.maxLength', { max: 255 }),
      (v: string) => (v && v.trim().length >= 5)
        || this.$t('validations.minLength', { min: 5 }),
    ];
  }
}
