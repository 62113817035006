





















































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { TreeView } from '@/types/course.type';

@Component({
  name: 'recursive-ilos-panel',
})
export default class RecursiveILOsPanel extends Vue {
  @Prop({ default: [] }) readonly ilos!: TreeView[];

  @Prop({ default: '' }) readonly parentIndex!: string;

  @Prop({ default: 0 }) readonly level!: number;

  opened: number[] = [];

  onClickSpacebar(event: KeyboardEvent, item: TreeView): void {
    // eslint-disable-next-line no-param-reassign
    item.name = `${item.name} `;
    event.preventDefault();
  }

  ilosInputsRules(max: number) {
    return [
      (v: string) => !!v || this.$t('validations.required'),
      (v: string) => (v && v.trim().length <= max) || this.$t('validations.maxLength', { max }),
      (v: string) => (v && v.trim().length >= 5) || this.$t('validations.minLength', { min: 5 }),
    ];
  }

  get rules() {
    return (index: string) => {
      const max = index.split('.').length <= 2 ? 50 : 255;
      return this.ilosInputsRules(max);
    };
  }

  @Watch('ilos')
  openPanels(): void {
    this.opened = this.ilos.map((ilo, index) => index);
  }

  mounted() {
    this.openPanels();
  }

  disableAddBtn(item: TreeView, index: string) {
    const indices = index ? index.split('.') : [];
    return (
      ((!!indices.length || item.children.length >= 10)
        && (!indices.length || item.children.length >= 30))
      || indices.length > 4
      || (item.used && item.children.length === 0)
      || !this.validateItemName(item.name ? item.name.trim() : '', indices.length)
    );
  }

  validateItemName(itemName: string, indicesLength: number): boolean {
    return (
      !!itemName
      && itemName.length >= 5
      && ((indicesLength <= 2 && itemName.length <= 50)
        || (indicesLength > 2 && itemName.length <= 255))
    );
  }

  raiseAppendChildEvent(props: { children: TreeView[]; index: string }) {
    this.$emit('append-ilo-child', props);
  }

  appendChild(children: TreeView[], index: string): void {
    const indices = index.split('.');
    this.opened.push(parseInt(indices[indices.length - 2], 10) - 1);
    this.raiseAppendChildEvent({ children, index });
  }

  raiseDeleteElementEvent(props: { index: string }) {
    this.$emit('delete-ilo-element', { index: props.index });
  }

  deleteElement(index: string): void {
    this.raiseDeleteElementEvent({ index });
  }
}
