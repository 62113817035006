/* eslint-disable @typescript-eslint/no-explicit-any */

import {

  IlosDataResponse,

} from '@/types/ilo.type';
import { Lang } from '@/types/general.type';
import {

  CourseIlo, CourseFlexibleIlo, TreeView,
} from '@/types/course.type';
import provider from './main.provider';

class IloProvider {
   idForExpand = 0;

   mapIlos(courseIlos) {
     this.idForExpand = 0;
     return courseIlos.reduce((acc, ilo: CourseIlo) => {
       if (ilo.iloId) {
         const headerIndex = acc.findIndex(
           (c) => c.headerId === ilo.headerId,
         );
         if (headerIndex === -1) {
           acc.push(this.addHeaderIlo(ilo));
         } else {
           const iloIndex = acc[headerIndex].children.findIndex(
             (c) => c.iloId === ilo.iloId,
           );
           if (iloIndex === -1) {
             acc[headerIndex].children.push(this.addIlo(ilo));
           } else {
             acc[headerIndex].children[iloIndex].children.push(
               this.addSubIlo(ilo),
             );
           }
         }
       }
       return acc;
     }, []);
   }

   mapFlexibleIlos(ilosList) {
     return ilosList.map((ilo: CourseFlexibleIlo) => {
       this.idForExpand += 1;
       return {
         id: this.idForExpand,
         name: ilo.iloDescription,
         code: ilo.iloCode,
         children: ilo.children && ilo.children.length ? this.mapFlexibleIlos(ilo.children) : [],
         iloItemId: ilo.iloId,
         used: ilo.used,
       };
     });
   }

   addHeaderIlo(ilo) {
     this.idForExpand += 1;
     return {
       id: this.idForExpand,
       headerId: ilo.headerId,
       name: ilo.headerName,
       deleted: false,
       code: ilo.headerCode,
       children: [this.addIlo(ilo)],
     };
   }

   addIlo(ilo) {
     this.idForExpand += 1;
     return {
       id: this.idForExpand,
       iloId: ilo.iloId,
       name: ilo.iloDescription,
       code: ilo.iloCode,
       deleted: false,
       children: ilo.subIloId ? [this.addSubIlo(ilo)] : [],
     };
   }

   addSubIlo(ilo) {
     this.idForExpand += 1;
     return {
       id: this.idForExpand,
       subIloId: ilo.subIloId,
       code: ilo.subIloCode,
       deleted: false,
       name: ilo.subIloDescription,
     };
   }

   async GetIlos(appId: string, courseId: string, query = {}): Promise<IlosDataResponse> {
     const result: IlosDataResponse = await provider.get(`/api/ilo/${appId}/${courseId}/GetIlos`, {
       params: query,
     });

     if (result.courseIlos) {
       if (result.course.flexibleIlos) {
         result.courseIlos = this.mapFlexibleIlos(result.courseIlos);
       } else {
         result.courseIlos = this.mapIlos(result.courseIlos);
       }
     } else {
       result.courseIlos = [];
     }

     return result;
   }

   async AddIlos(
     appId: string, courseId: string, reqBody: object,
   ): Promise<{ message: Lang }> {
     const res: {message: Lang } = await provider.post(
       `/api/ilo/${appId}/${courseId}/saveIlos`,
       reqBody,
     );
     return res;
   }

   async AddFlexibleILOs(
     appId: string,
     courseId: string,
     ilos: TreeView[],
   ): Promise<{ message: Lang; success: boolean }> {
     const res: { message: Lang; success: boolean } = await provider.post(
       `/api/ilo/${appId}/${courseId}/saveFlexibleILOs`,
       {
         ilos,
       },
     );
     return res;
   }

   async DeleteIlos(
     appId: string, courseId: string,
   ): Promise<{ message: Lang }> {
     const res: {message: Lang } = await provider.delete(
       `/api/ilo/${appId}/${courseId}/deleteIlos`,
     );
     return res;
   }
}

export default new IloProvider();
